.homepagestyle {
  overflow-y: auto;
  display: block;
}
.herotext {
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  font-size: 2rem;
}

.description {
  letter-spacing: 0px;
  word-spacing: 2px;
  padding: 10px;
  width: 60%;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  text-align: center;
}

.actionelements {
  display: flex;
  gap: 20px;
  align-items: center;
}

.button {
  padding: 10px;
  border: 2px solid white;
  border-radius: 9px;
  background-color: black;
  color: white;
  font-weight: bold;
  font-size: 0.8rem;
  letter-spacing: 1px;
}

.mobilebttn:hover {
  transform: none;
  transition: none;
  cursor: pointer;
}

.mobilebttn:active {
  transform: scale(1.1, 1.1);
  transition: transform 0.1s ease;
  cursor: pointer;
}

.portfolio {
  background-color: #9b17ff;
  border: 0px;
}

.typedtext {
  text-align: center;
  font-family: "Poppins", sans-serif;
  display: flex;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.8rem;
}

.smallfontsize {
  font-size: 0.8rem;
}

.mobilephoto {
  height: 100px;
  width: 100px;
  border-radius: 100%;
}
