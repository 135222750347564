.mobilenavbar {
  /* position: absolute;
  top: 5%; */
  margin-top: 10px;
  margin-left: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  align-self: flex-start;
}
.photo {
  height: 80px;
  width: 80px;
  border-radius: 100%;
}

.name {
  width: 70%;
  font-family: "Calibri", serif;
  font-size: 1.5rem;
}

.menu {
  height: 20px;
  width: 20px;
}

.overlaymenuenable {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  /* left: 0; */
  display: flex;
  animation: openmenu 0.5s forwards;
  /* background-color: rgba(255, 255, 255, 0.1); */
}

.emptyelement {
  width: 50%;
}

.close {
  height: 30px;
  width: 30px;
  align-self: flex-end;
}
.rightsidemenu {
  background-color: white;
  color: black;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 0px 10px 10px 00px;
}

.overlaymenudisable {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  display: flex;
  /* background-color: rgba(255, 255, 255, 0.1); */
  animation: closemenu 0.5s forwards;
}

.menudisable {
  display: none;
}

@keyframes closemenu {
  from {
    left: 0%;
  }
  to {
    left: -100%;
  }
}

@keyframes openmenu {
  from {
    left: -100%;
  }
  to {
    left: 0%;
  }
}
