.contactme {
  /* margin: auto; */
  padding: 10px;
  /* align-self: flex-start; */
  /* display: block; */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.contactme {
  /* width: max-content; */
  padding: 40px;
  border: 3px solid white;
  border-radius: 10px;
  font-size: 0.8em;
  letter-spacing: 1px;
}

.mobileversion {
  /* width: max-content; */
  font-size: 0.8rem;
  padding: 10px;
}
