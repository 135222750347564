::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: black;
}

::-webkit-scrollbar-thumb {
  background: #1a1919;
}
/* 
::-webkit-scrollbar-thumb:hover {
  background: grey;
} */

.rightsidebar {
  margin: 10px;
  flex: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  overflow-y: auto;
}

.highlightText {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.1em;
}

.growElement:hover {
  transform: scale(1.2, 1.2);
  transition: transform 0.2s ease;
  cursor: pointer;
}
