.socials {
  margin-top: auto;
  margin-bottom: 50px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 30px;
  font-size: 0.8rem;
}

.sociallinks {
  color: inherit;
  letter-spacing: 1px;
  text-decoration: none;
  font-weight: bold;
}
/* 
.sociallinks:hover {
  transform: scale(1.2, 1.2);
  transition: transform 0.2s ease;
} */
