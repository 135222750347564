.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: black;
  color: white;
  font-size: 20px;
  letter-spacing: 3px;
}

.AppMobile {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: black;
  color: white;
  font-size: 20px;
  letter-spacing: 3px;
}
