.aboutmecss {
  /* margin: 30px; */
  padding: 10px;
  overflow-y: auto;
  width: 80%;
  display: flex;
  gap: 50px;
  flex-direction: column;
}

.Heading {
  margin-bottom: 10px;
  text-decoration: underline;
  width: max-content;
  font-size: 1.3rem;
}

.Section {
  color: rgba(255, 255, 255, 0.6);
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 1rem;
  letter-spacing: 1px;
  word-spacing: 2px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
