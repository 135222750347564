.leftsidebar {
  flex: 20%;
  margin-left: 50px;
  margin-top: 50px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
}
.profilephoto {
  height: 120px;
  width: 120px;
  border-radius: 100%;
  margin-bottom: 20px;
}

.profilename {
  width: 10%;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.mobileNavbar {
  display: none;
}
