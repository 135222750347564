.skilltable {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 400px));
  row-gap: 5px;
}

.skillheading {
  grid-column: 1/2;
}

.skillData {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.skilllevel {
  height: 7px;
  width: 80%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  font-size: 1rem;
}
