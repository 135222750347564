a {
  text-decoration: none;
  color: inherit;
}

.subelements {
  cursor: pointer;
  font-size: 1rem;
  letter-spacing: 2px;
  text-decoration: none;
  color: inherit;
  color: grey;
  width: max-content;
}

/* .subelements:hover {
  transform: translateX(30px) scale(1.2, 1.2);
  transition: transform 0.2s ease;
} */

.navigateActive {
  color: white;
}

.mobileActive {
  color: black;
}
