.works {
  margin: 20px;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 50px;
}

.workcards {
  /* height: 80%;
  width: 80%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.workDesc {
  font-size: 1rem;
}

.workImg {
  height: 80%;
  width: 80%;
}

.codesource {
  border: 1px solid white;
  padding: 10px;
  border-radius: 10px;
  font-size: 1rem;
}
