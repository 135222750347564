.download {
  font-size: 1rem;
}

.downloadStyling {
  border: 2px solid white;
  border-radius: 10px;
  padding: 10px;
  margin-top: 100px;
  text-align: center;
  width: fit-content;
}

.downloadStyling:hover {
  /* background-color: #9b17ff;
  border: 0px; */
  transform: scale(1.15, 1.15);
  transition: transform 0.1s ease-in-out;
  cursor: pointer;
}
